import { useQuery } from '@apollo/client';
import { DeleteIcon, DragHandleIcon } from '@chakra-ui/icons';
import {
	Box,
	Select,
	Checkbox,
	Heading,
	IconButton,
	Flex,
	Input,
	useToast,
	Button,
	Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, Text
} from '@chakra-ui/react';
import axios from 'axios';
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../../Constants';
import {
	DoctorEntity, HL7ProviderEntity,
	MeasurementEntity,
	PatientEntity,
	ReportEntity, SessionEntity,
	StudyEntity,
	UserEntity,
} from '../../../Models/Entities';
import { genderType, reportStatus } from '../../../Models/Enums';
import { store } from '../../../Models/Store';
import { getHumanDisplayUnit, getHumanReadableName, getSRPathLabel, getValuePrecision, getVariableLabel } from '../../../Util/measurements_template';
import { AutoResizeTextarea } from '../../Components/AutoExpandTextArea/AutoExpandTextArea';
import ConfirmedAlreadyModal from '../Modals/ConfirmedAlreadyModal';
import SaveModal from '../Modals/SaveModal';
import UrgencyConfirmationModal from '../Modals/UrgencyConfirmationModal';

interface NewAdvancedReportBuilderProps {
	study: StudyEntity;
	refetch: () => void;
	detectChanges?: () => void;
}

export interface NewAdvancedReportBuilderMethods {
	// Define your methods here
	quietSave: () => Promise<boolean>;
}

interface Demographics {
	clinicalDetails: string;
	gender: genderType;
	sonographer: string;
	trainee: string;
	referringDoctor: {
		prefix: string;
		firstName: string;
		lastName: string;
	}
	assignedDoctor: string;
	reportTemplate: string;
}

const initialDemographics: Demographics = {
	clinicalDetails: '',
	gender: 'NA',
	sonographer: '',
	trainee: '',
	referringDoctor: {
		prefix: 'Dr',
		firstName: '',
		lastName: '',
	},
	assignedDoctor: '',
	reportTemplate: '',
};
const NewAdvancedReportBuilder: ForwardRefRenderFunction<NewAdvancedReportBuilderMethods, NewAdvancedReportBuilderProps> = (props, ref) => {
	const { study, refetch, detectChanges } = props;

	const { loading: userLoading, data: user, error: userError} = useQuery(UserEntity.getFetchSingleQueryProfilePage(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: store.userId,
			}]
		}
	})

	const { loading: measurementsLoading, data: measurements, error: measurementError} = useQuery(MeasurementEntity.fetchMeasurements(), {
		fetchPolicy: 'network-only',
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: study.id,
			}]
	 	}
	})
	
	const { userEntity: loggedInUser } = user || {};

	const [isLoading, setIsLoading] = useState(true);
	const [isUrgencyModalOpen, setIsUrgencyModalOpen] = useState<boolean>(false);
	const [confirmedAlreadyModalOpen, setConfirmedAlreadyModalOpen] = useState<boolean>(false);

	const [observations, setObservations] = useState({});
	const [conclusions, setConclusions] = useState<{ id: string; sentence: string }[]>([]);

	const [demographics, setDemographics] = useState<Demographics>(initialDemographics)
	const [sonographers, setSonographers] = useState<string[]>([''])
	const [sentenceTemplates, setSentenceTemplates] = useState<string[]>([])
	const [assignedDoctors, setAssignedDoctors] = useState<string[]>([''])
	const [trainees, setTrainees] = useState<string[]>([''])
	const [reportTemplates, setReportTemplates] = useState<string[]>([])
	const [urgent, setUrgent] = useState<boolean>(false);
	const [exiting, setExiting] = useState<boolean>(false);
	const [confirming, setConfirming] = useState<boolean>(false);
	const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false);
	const [ccRecipients, setCcRecipients] = useState<string>('');
	const [recommendations, setRecommendations] = useState<string>('');
	const [sentenceTemplate, setSentenceTemplate] = useState<string>('');
	
	const { t, i18n } = useTranslation();
	const [translations, setTranslations] = useState('');

	const toast = useToast();
	const navigate = useNavigate();
	
	useImperativeHandle(ref, () => ({
		quietSave,
	}))

	const measurementMap = new Map();
	const variables = {};

	const loadTranslationFile = async (variables: any) => {
		if (study.patient.site.translationFileId) {
			try {
				const translationFile = await axios.get(`${SERVER_URL}/api/files/${study.patient.site.translationFileId}?download=true`);
				i18n.addResourceBundle('en', 'translation', translationFile.data, false, true);
				setTranslations(t(`${study.studyType.toLowerCase()}`, { returnObjects: true, ...variables }));
			} catch (err) {
				console.error(err);
			}
		} else {
			setTranslations(t(`${study.studyType.toLowerCase()}`, { returnObjects: true, ...variables }));
		}
	};

	useEffect(() => {
		if (translations !== '') {
			setTranslationsLoaded(true);
		}
	}, [translations]);
	
	useEffect(() => {
		if (translationsLoaded) {
			setObservationState();
			populateDemographics();
			populateConclusions();
			populateCcRecipients();
			populateRecommendations();
			populateSentenceTemplate();
			fetchDoctors();
			fetchSentenceTemplates();
			fetchReportTemplates();

			if (!study.report?.reportDataGenerated) {
				setPreSelections();
			} else {
				setIsLoading(false);
			}
		}
	}, [translationsLoaded])

	useEffect(() => {
		if (!study.sonographer) {
			preSelectSonographer();
		}
	}, [sonographers])

	useEffect(() => {
		if (study.report?.reportDataGenerated !== null && !study.report?.reportDataGenerated) {
			if (sentenceTemplate !== 'N/A' && sentenceTemplate !== '') {
				const updatedObservations = { ...observations };

				const mergedObservations = mergeObjects(updatedObservations, translations['sentenceTemplates'][sentenceTemplate])

				setObservations(prevObservations => ({ ...prevObservations, ...mergedObservations }));
				if (detectChanges) {
					detectChanges();
				}
			}
		}
	}, [sentenceTemplate])


	useEffect(() => {
		if (!measurementsLoading) {
			measurements.measurementEntitys.forEach((measurement: MeasurementEntity) => {
				const { name, isPrimary, value } = measurement;

				const existingMeasurement = measurementMap.get(name);
				let instruction;
				try {
					instruction = JSON.parse(study.measurementStructure)[name];
				} catch (error) {
					// measurementStructure probably just doesn't exist yet no biggy.
				}
				
				if (instruction) {
					switch (instruction.defaultValue) {
						case 'Single':
							if (!existingMeasurement || (isPrimary && !existingMeasurement.isPrimary)) {
								measurementMap.set(name, measurement);
							}
							break;
						case 'Average':
							if (!existingMeasurement) {
								measurementMap.set(name, { ...measurement, sum: value, count: 1 });
							} else {
								const updatedSum = existingMeasurement.sum + value;
								const updatedCount = existingMeasurement.count + 1;
								measurementMap.set(name, { ...measurement, value: updatedSum / updatedCount, sum: updatedSum, count: updatedCount });
							}
							break;
						case 'Max':
							if (!existingMeasurement || value > existingMeasurement.value) {
								measurementMap.set(name, measurement);
							}
							break;
						case 'Min':
							if (!existingMeasurement || value < existingMeasurement.value) {
								measurementMap.set(name, measurement);
							}
							break;
						default:
							break;
					}
				} 
				
				else {
					if (!existingMeasurement || (isPrimary && !existingMeasurement.isPrimary)) {
						measurementMap.set(name, measurement);
					}
				}
			});

			measurementMap.forEach(measurement => {
				const { name, value } = measurement;
				const SRPathLabel = getVariableLabel(name);
				const valuePrecision = getValuePrecision(name, value);
				const humanName = getHumanReadableName(name);
				const displayUnit = getHumanDisplayUnit(name)?.replace('m2', 'm²');

				variables[SRPathLabel] = {
					name: humanName,
					unit: displayUnit,
					value: valuePrecision
				};
			});

			loadTranslationFile(variables);
			props.study.measurementss = measurements.measurementEntitys;
		}
	}, [measurementsLoading]);

	const quietSave = async (): Promise<boolean> => {
		let success = false;
		if (study.report?.reportStatus !== 'CONFIRMED') {
			if (study.report) {
				const report = new ReportEntity(study.report);
				updateReferringDoctor();
				report.reportDataGenerated = JSON.stringify({
					observation: observations,
					conclusions: conclusions,
					ccRecipients: ccRecipients,
					recommendations: recommendations,
					sentenceTemplate: sentenceTemplate,
				});

				let newReport = '';

				for (const category in observations) {
					const content = concatSentences(observations[category]);

					if (content.trim() !== '') {
						newReport += `${category}: ${content}\n`;
					} else {
						if (observations[category].sentence) {
							newReport += `${category}: ${observations[category].sentence}\n`;
						}
					}
				}

				report.reportData = newReport;

				try {
					await report.save();
					success = true;
				} catch (error) {
					console.error('Error saving report:', error);
					// Handle the error as needed.
				}
			}

			// Check to make sure gender matches
			if (study.patient.gender !== demographics.gender) {
				const patient = new PatientEntity(study.patient);

				patient.gender = demographics.gender;

				try {
					await patient.save();
					success = true;
				} catch (error) {
					console.error('Error saving patient:', error);
					// Handle the error as needed.
				}
			}

			const updatedStudy = new StudyEntity(study);
			updatedStudy.sonographer = demographics.sonographer;
			updatedStudy.trainee = demographics.trainee;
			updatedStudy.assignedDoctor = demographics.assignedDoctor;
			updatedStudy.clinicalDetails = demographics.clinicalDetails;
			updatedStudy.selectedReportTemplate = demographics.reportTemplate;

			try {
				await updatedStudy.save();
				success = true;
			} catch (error) {
				console.error('Error saving study:', error);
				// Handle the error as needed.
			}

			if (success) {
				refetch();
			} else {
				console.error('Error saving study');
				// Handle the error as needed.
			}
		}
		return success;
	}
	
	const preSelectSonographer = async () => {
		if (sonographers && loggedInUser) {
			if (sonographers.includes(loggedInUser.name)) {
				setDemographics({ ...demographics, sonographer: loggedInUser.name });
			}
		}

		return '';
	};

	const getReadOnly = (): boolean => {
		// If study is confirmed and user is not the reporting doctor
		if (study.report?.reportStatus === 'CONFIRMED' && store.userId !== study.report?.reportingDoctor) {
			return true;
		}

		if (store.userType === 'SITE_ADMIN' || store.userType === 'SITE_USER') {
			return true;
		}
		
		if (store.userId === study.report?.reportingDoctor) {
			return false;
		}
		
		const patientSiteId = study.patient.siteId;

		for (const siteAccess of loggedInUser?.sitess || []) {
			if (siteAccess.siteId === patientSiteId) {
				// Found a match for the siteId, extract the access level
				return siteAccess.accessLevel === 'VIEW_ONLY';
			}
		}

		return true;
	}
	
	const mergeObjects = (obj1: any, obj2: any): any => {
		const result: any = { ...obj1 };

		for (const key in obj2) {
			
			if (obj2.hasOwnProperty(key)) {
				if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
					if (Array.isArray(obj2[key])) {
						result[key] = { ...obj1[key], ...obj2[key][0], value: obj2[key][0].display };
						result[key].conclusion = false;
						delete result[key].display;
					} else {
						result[key] = mergeObjects(obj1[key], obj2[key]);
					}
				} else {
					result[key] = obj2[key];
				}
			}
		}

		return result;
	};
	
	const setObservationState = () => {
		if (study?.report?.reportDataGenerated) {
			const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);

			if (advancedReportDetails?.observation) {
				setObservations(prevObservations => ({ ...prevObservations, ...advancedReportDetails.observation }));
			}
		} else if (translations?.['advancedReportBuilder'] && Object.keys(translations['advancedReportBuilder']).length > 0) {
			const updatedObservations: Record<string, Record<string, {
				value: string;
				conclusion: boolean;
				output: string
			}>> = {};

			Object.keys(translations['advancedReportBuilder']).forEach(segment => {
				updatedObservations[segment] = {};
				Object.keys(translations['advancedReportBuilder'][segment]).forEach(element => {
					updatedObservations[segment][element] = { value: '', conclusion: false, output: '' };
				});
			});

			setObservations(prevObservations => ({ ...prevObservations, ...updatedObservations }));
		}
	};

	const addEmptyConclusion = () => {
		// Generate a unique ID (you can use timestamp or any other method)
		const uniqueId = new Date().getTime().toString();

		// Create a new empty object
		const newObject = {
			'id': uniqueId,
			'sentence': ''
		};

		// Update the state by appending the new object
		setConclusions(prevData => [...prevData, newObject]);
	};

	const fetchDoctors = async () => {
		try {
			const sonographersResponse = await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetSonographers/${study.patient.siteId}`);
			if (sonographersResponse.data !== '') {
				const sonographersData = sonographersResponse.data;
				setSonographers(sonographersData);
			}

			const reportingDoctorsResponse = await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetReportingDoctors/${study.patient.siteId}`);
			if (reportingDoctorsResponse.data !== '') {
				const reportingDoctorsData = reportingDoctorsResponse.data;
				setAssignedDoctors(reportingDoctorsData);
			}

			const traineesResponse = await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetTrainees/${study.patient.siteId}`);
			if (traineesResponse.data !== '') {
				const traineesData = traineesResponse.data;
				setTrainees(traineesData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const fetchSentenceTemplates = () => {
		setSentenceTemplates(Object.keys(translations['sentenceTemplates']))
	}

	const fetchReportTemplates = () => {
		const templates: string[] = [];
		templates.push('Default');

		if(study.patient.site.reportTemplates) {
			Object.keys(study.patient.site.reportTemplates).forEach((template: string) => {
				templates.push(study.patient.site.reportTemplates[template].templateName);
			});
			if (templates.length > (study.patient.site.reportTemplates.length + 1)) {
				templates.pop();
			}
		}
		setReportTemplates(templates);
	}

	const populateDemographics = () => {
		const clinicalDetails = study.clinicalDetails;
		const gender =  study.patient.gender;
		const sonographer = study.sonographer || '';
		const trainee =  study.trainee || '';
		const assignedDoctor = study.assignedDoctor || '';

		const reportTemplate = preSelectReportTemplate();

		// Get referring doctor
		const referringDoctor = study.doctorss.find(doctor => doctor.doctorType === 'REFERRING');

		const updatedDemographics: Demographics = {
			clinicalDetails: clinicalDetails,
			gender: gender,
			sonographer: sonographer,
			trainee: trainee,
			assignedDoctor: assignedDoctor,
			reportTemplate: reportTemplate,
			referringDoctor: {
				prefix: referringDoctor?.prefix ? referringDoctor?.prefix : 'Dr',
				firstName: referringDoctor?.firstName ?? '',
				lastName: referringDoctor?.lastName ?? ''
			}
		};

		setDemographics(updatedDemographics);
	};

	const populateConclusions = () => {
		if (study.report?.reportDataGenerated) {
			const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);

			if (advancedReportDetails?.conclusion?.conclusions) {
				setConclusions(advancedReportDetails.conclusion?.conclusions);
			} else if(advancedReportDetails?.conclusions) {
				setConclusions(advancedReportDetails?.conclusions);
			}
		}
	}

	const populateCcRecipients = () => {
		if (study.report?.reportDataGenerated) {
			const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);

			if (advancedReportDetails?.ccRecipients) {
				setCcRecipients(advancedReportDetails.ccRecipients);
			}
		}
	}

	const populateRecommendations = () => {
		if (study.report?.reportDataGenerated) {
			const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);

			if (advancedReportDetails?.recommendations) {
				setRecommendations(advancedReportDetails.recommendations);
			}
		}
	}

	const populateSentenceTemplate = () => {
		if (study.report?.reportDataGenerated) {
			const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);

			if (advancedReportDetails?.sentenceTemplate) {
				setSentenceTemplate(advancedReportDetails.sentenceTemplate);
			}
		}
	}

	const multiConditional = (section: string, element: string, gender: string, measurementLabel: string): void => {
		const measurementLabels = measurementLabel.split(' && ');
		const recordedMeasurements = [] as MeasurementEntity[];

		measurementLabels.forEach(label => {
			// @ts-ignore
			const measurement = measurements?.measurementEntitys?.find(m => m.name === getVariableLabel(label));
			if (measurement && measurement.value !== undefined) {
				recordedMeasurements.push(measurement);
			}
		});

		const matches = [] as string[];
		recordedMeasurements.forEach(recordedMeasurement => {
			for (let i = 0; i <= Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel]).length - 1; i += 2) {
				const keyOne = Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel])[i];
				const keyTwo = Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel])[i + 1];
				if (recordedMeasurement.value >= translations['prepopulationConditions'][section][element][gender][measurementLabel][keyOne]
					&& recordedMeasurement.value <= translations['prepopulationConditions'][section][element][gender][measurementLabel][keyTwo]
				) {
					const optionKey = Object.keys(translations['prepopulationConditions'][section][element])[i / 2];
					matches.push(optionKey);
				}
			}
		});
		if (matches.length > 1 && [...new Set(matches)].length === 1) {
			if (observations[section] === undefined || observations[section][element].value === '' || observations[section][element].value === null || observations[section][element].value === undefined) {
				handleOptionSelect(section, element, translations['prepopulationConditions'][section][element][matches[0]]);
			}
		}
	};

	const singleConditional = (section: string, element: string, gender: string, measurementLabel: string): void => {
		// @ts-ignore
		const recordedMeasurement = measurements?.measurementEntitys?.find(m => getVariableLabel(m.name) === getVariableLabel(measurementLabel));

		if (recordedMeasurement?.value !== undefined) {
			for (let i = 0; i <= Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel]).length - 1; i += 2) {
				const keyOne = Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel])[i];
				const keyTwo = Object.keys(translations['prepopulationConditions'][section][element][gender][measurementLabel])[i + 1];

				if (recordedMeasurement.value >= translations['prepopulationConditions'][section][element][gender][measurementLabel][keyOne]
					&& recordedMeasurement.value <= translations['prepopulationConditions'][section][element][gender][measurementLabel][keyTwo]
				) {
					if (observations[section] === undefined || observations[section][element].value === '' || observations[section][element].value === null || observations[section][element].value === undefined) {
						const optionKey = Object.keys(translations['prepopulationConditions'][section][element])[i / 2];
						handleOptionSelect(section, element, translations['prepopulationConditions'][section][element][optionKey]);
					}
				}
			}
		}
	};

	const genderOptions = (section: string, element: string): void => {
		const gender = study.patient.gender.toString().toLowerCase();

		if (gender === 'male' || gender === 'female') {
			Object.keys(translations['prepopulationConditions'][section][element][gender]).forEach(measurementLabel => {
				if (measurementLabel.includes('&&')) {
					multiConditional(section, element, gender, measurementLabel);
				} else {
					singleConditional(section, element, gender, measurementLabel);
				}
			});
		} else {
			// do nothing; 
		}
	};

	const neutralOptions = (section: string, element: string): void => {
		Object.keys(translations['prepopulationConditions'][section][element]).forEach(measurementLabel => {
			// @ts-ignore
			const recordedMeasurement = measurements?.measurementEntitys?.find(m => getVariableLabel(m.name) === getVariableLabel(measurementLabel));

			if (recordedMeasurement !== undefined && recordedMeasurement.value !== undefined) {
				for (let i = 0; i <= Object.keys(translations['prepopulationConditions'][section][element][measurementLabel]).length - 1; i += 2) {
					const keyOne = Object.keys(translations['prepopulationConditions'][section][element][measurementLabel])[i];
					const keyTwo = Object.keys(translations['prepopulationConditions'][section][element][measurementLabel])[i + 1];

					if (
						recordedMeasurement.value >= translations['prepopulationConditions'][section]?.[element]?.[measurementLabel]?.[keyOne]
						&& recordedMeasurement.value <= translations['prepopulationConditions'][section]?.[element]?.[measurementLabel]?.[keyTwo]
					) {
						if (
							observations[section]?.[element]?.value === ''
							|| observations[section]?.[element]?.value === null
							|| observations[section]?.[element]?.value === undefined
						) {
							const optionKey = Object.keys(translations['prepopulationConditions'][section][element])[i / 2];
							handleOptionSelect(section, element, translations['prepopulationConditions'][section][element]?.[optionKey]);
						}
					}
				}
			}
		});
	};

	const preSelectReportTemplate = () => {
		if (study.selectedReportTemplate) {
			return study.selectedReportTemplate;
		}

		if (study.patient.site.reportTemplates && study.studyType) {
			const typeToPropertyMap = {
				'ECHO': 'defaultEchoTemplate',
				'STRESS_ECHO': 'defaultStressTemplate',
				'TRANSOESOPHAGEAL_ECHO': 'defaultTransTemplate',
				'CTCA': 'defaultCtcaTemplate',
				'CTCS': 'defaultCtcsTemplate',
				'PAEDIATRIC_ECHO': 'defaultPaediatricTemplate'
			};

			const property = typeToPropertyMap[study.studyType];


			const reportTemplate = property
				? study.patient.site.reportTemplates.find(template => template[property])
				: null;

			if (reportTemplate) {
				return reportTemplate.templateName;
			}
		}

		return 'Default';
	}

	const setPreSelections = () => {
		Object.keys(translations['prepopulationConditions']).forEach(section => {
			Object.keys(translations['prepopulationConditions'][section]).forEach(element => {
				if (Object.keys(translations['prepopulationConditions'][section][element]).includes('male')) {
					genderOptions(section, element);
				} else {
					neutralOptions(section, element);
				}
			});
		});
		
		setIsLoading(false);
	}

	const handleOptionSelect = (section: string, element: string, option: string) => {
		if(detectChanges) {detectChanges();}
		setObservations(prevObservations => {
			const updatedObservations = { ...prevObservations };

			if (!updatedObservations[section]) {
				updatedObservations[section] = {};
			}
			if (!updatedObservations[section][element]) {
				updatedObservations[section][element] = { value: undefined };
			}

			updatedObservations[section][element].value = option;

			const matchingObject = translations['advancedReportBuilder'][section][element].find((obj: { display: string, conclusion: string, sentence: string }) => obj.display === option);
			let sentenceToUpdate: string;

			if (matchingObject && matchingObject.sentence !== undefined) {
				sentenceToUpdate = matchingObject.sentence;
			} else {
				sentenceToUpdate = '';
			}

			updatedObservations[section][element].sentence = cleanSentence(sentenceToUpdate); // Replace the existing sentence

			const measurementLabelMap = translations['measurementPanel']['measurementLabels'];

			Object.keys(measurementLabelMap).forEach(key => {
				const varLabel = getSRPathLabel(key);
				const reportLabel = measurementLabelMap[key];

				if (matchingObject && matchingObject.conclusion) {
					if (matchingObject.conclusion.includes(varLabel)) {
						updatedObservations[section][element].conclusion = matchingObject.conclusion.replace(varLabel, reportLabel);

						const hasMeasurement = matchingObject.conclusion.match(`${reportLabel}\\s?\\d+`);
						if (hasMeasurement === null) {
							updatedObservations[section][element].conclusion = matchingObject.conclusion
								.replace(reportLabel, '')
								.replace('(  , ', '')
								.replace(',  ,', ',')
								.replace(',  .', '.')
								.replace(',  )', ')');
						}
					}
				}

			});

			return updatedObservations;
		});
	}

	const handleCheckboxChange = (section: string, value: string) => {
		if(detectChanges) {detectChanges();}
		const updatedObservations = { ...observations };
		updatedObservations[section][value]['conclusion'] = !updatedObservations[section][value]['conclusion'];
		setObservations(updatedObservations);
		
		const existingItemIndex = conclusions.findIndex(item => item.id === section + '--' + value);
		
		if (existingItemIndex !== -1) {
			// Remove the item if it already exists in the state
			setConclusions(prevState => prevState.filter((_, index) => index !== existingItemIndex));
		} else {
			// Add a new item to the state
			console.log('section: ', section);
			console.log('value: ', value);
			console.log(translations)
			console.log(translations['advancedReportBuilder'][section][value])
			const matchValue = translations['advancedReportBuilder'][section][value].find((obj: any) => obj.display === observations[section]?.[value].value)

			if (matchValue.conclusion) {
				setConclusions(prevState => [
					...prevState,
					{ id: section + '--' + value, sentence: matchValue.conclusion },
				]);
			}
		}
	};
	const updateReferringDoctor = () => {
		let referringDoctor = study.doctorss.find(doctor => doctor.doctorType === 'REFERRING');

		if (demographics.referringDoctor.prefix.trim() === ''
			|| demographics.referringDoctor.firstName.trim() === ''
			|| demographics.referringDoctor.lastName.trim() === '') {

			toast({
				title: 'Referring doctor must be populated',
				description: 'Enter a referring doctor',
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});

			return false;
		}

		if (referringDoctor) {
			const newReferringDoctor = new DoctorEntity(referringDoctor);
			newReferringDoctor.prefix = demographics.referringDoctor.prefix;
			newReferringDoctor.firstName = demographics.referringDoctor.firstName;
			newReferringDoctor.lastName = demographics.referringDoctor.lastName;

			newReferringDoctor.save();
		} else {
			referringDoctor = new DoctorEntity();
			referringDoctor.prefix = demographics.referringDoctor.prefix;
			referringDoctor.firstName = demographics.referringDoctor.firstName;
			referringDoctor.lastName = demographics.referringDoctor.lastName;
			referringDoctor.doctorType = 'REFERRING';
			referringDoctor.studyId = study.id;
			referringDoctor.save();
		}

		return true;
	}

	const checkPermissions = (confirm: boolean) => true

	const checkEditable = () => !(study.report?.reportStatus === 'CONFIRMED' && store.userType === 'CARDIAC_SCIENTIST')

	const saveObservations = (urgency: boolean, exit: boolean, confirm: boolean) => {
		setUrgent(urgency);
		setExiting(exit);
		setConfirming(confirm);

		if (!checkPermissions(confirm)) {
			return
		}

		if (!study.urgent && urgency) {
			setIsUrgencyModalOpen(true)
			return;
		}

		if (study.report?.reportStatus === 'CONFIRMED') {
			setConfirmedAlreadyModalOpen(true);
			return;
		}

		saveStudy(urgency, exit, confirm);
	}

	const saveUrgentStudy = (urgency: boolean, exit: boolean, confirm: boolean) => {
		if (study.report?.reportStatus === 'CONFIRMED') {
			setConfirmedAlreadyModalOpen(true);
			return;
		}

		saveStudy(urgency, exit, confirm);
	}

	const updateStudyStatus = (confirm: boolean, exit: boolean): reportStatus => {
		// Reporting Doctor

		if (store.userType.toLowerCase() === 'reporting_doctor') {
			if (confirm) {
				return 'CONFIRMED';
			} else if (exit) {
				return 'PROVISIONAL';
			}
		}

		if (store.userType.toLowerCase() === 'cardiac_scientist') {
			if (exit) {
				return 'PROVISIONAL'
			}
		}

		if (study.report?.reportStatus) {
			return study.report?.reportStatus;
		}

		return 'NOT_REPORTED';
	}

	const concatSentences = (obj: { [key: string]: any }): string => {
		let result = '';

		for (const key in obj) {
			if (obj[key] && obj[key].sentence) {
				result += obj[key].sentence + ' ';
			} else if (typeof obj[key] === 'object') {
				const subContent = concatSentences(obj[key]);
				if (subContent.trim() !== '') {
					result += subContent;
				}
			}
		}

		return result.trim();
	};
	const saveStudy = (urgency: boolean, exit: boolean, confirm: boolean) => {
		if (!updateReferringDoctor()) {
			return;
		}
		
		if (study.report) {
			const report = new ReportEntity(study.report);
			report.reportDataGenerated = JSON.stringify({
				observation: observations,
				conclusions: conclusions,
				ccRecipients: ccRecipients,
				recommendations: recommendations,
				sentenceTemplate: sentenceTemplate,
			});

			let newReport = '';
			

			for (const category in observations) {
				
				const content = concatSentences(observations[category]);
				
				if (content.trim() !== '') {
					newReport += `${category}: ${content}\n`;
				} else {
					if (observations[category].sentence) {
						newReport += `${category}: ${observations[category].sentence}\n`;
					}
				}
			}

			report.reportData = newReport;

			report.reportStatus = updateStudyStatus(confirm, exit);
			
			if (report.reportStatus === 'CONFIRMED') {
				report.reportingDoctor = store.userId || '';
				
				// Confirmed 
			}

			report.save().then(() => {
				if (report.reportStatus === 'CONFIRMED') {
					axios.get(`${SERVER_URL}/api/entity/StudyEntity/SendReport/${study.id}/${study.selectedReportTemplate}`);
				}
				toast({
					title: 'Report Saved',
					description: 'Report Saved',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			});
		}

		// Check to make sure gender matches
		if (study.patient.gender !== demographics.gender) {
			const patient = new PatientEntity(study.patient);

			patient.gender = demographics.gender;
			patient.save().then(() => {
				toast({
					title: 'Patient Gender',
					description: 'Patient Gender Updated',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			});
		}

		const updatedStudy = new StudyEntity(study);
		updatedStudy.sonographer = demographics.sonographer;
		updatedStudy.trainee = demographics.trainee;
		updatedStudy.assignedDoctor = demographics.assignedDoctor;
		updatedStudy.clinicalDetails = demographics.clinicalDetails;
		updatedStudy.selectedReportTemplate = demographics.reportTemplate;
		updatedStudy.urgent = urgency;
		
		updatedStudy.save().then(() => {
			toast({
				title: 'Study Saved',
				description: 'Study Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			refetch();
			
			// Trigger hl7 method
			if (store.userType === 'CARDIAC_SCIENTIST') {
				if (exit) {
					sendHl7();
				}
			} else {
				sendHl7();
			}
			
			if (exit) {
				navigate('/');
			}
		});

	}

	const sendHl7 = async () => {
		const { hL7ProviderId } = study.patient.site;

		if (hL7ProviderId) {
			axios.post(
				`${SERVER_URL}/api/entity/StudyEntity/hl7message/` + hL7ProviderId,
				{
					studyId: study.id,
					to: null,
					cc: null,
					documentTitle: study.studyType,
					correction: null,
					reportDetails: {
						firstName: study.patient.firstName,
						surname: study.patient.lastName,
						dob: new Date(study.patient.dob).toISOString(),
						requestDate: new Date().toISOString(),
						observationDate: new Date(study.studyDate).toISOString(),
					},
				},
			)
		}
	};


	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const items = Array.from(conclusions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		setConclusions(items);
	};

	const handleDeleteItem = (id: string) => {
		console.log(id);
		const updatedItems = conclusions.filter(item => item.id !== id);
		const [section, value] = id.split('--');

		if (section && value) {
			observations[section][value]['conclusion'] = false;
		}
		
		setConclusions(updatedItems);
	};
	
	const cleanSentence = (inputStr: string) => {
		let cleanedStr = inputStr.replace(/(\s*\(\s*(,\s*)+\))|(\s*\(\))/g, '');

		// Remove unwanted patterns like ", " or " , "
		cleanedStr = cleanedStr.replace(/,(\s*,)+/g, ',');

		// Remove trailing commas before closing parenthesis
		cleanedStr = cleanedStr.replace(/,\s*\)/g, ')');

		// Remove leading commas and spaces after opening parenthesis
		cleanedStr = cleanedStr.replace(/\(\s*,\s*/g, '(');

		return cleanedStr;
	}
	
	const updateSentenceState = (section: string, element: string, value: string) => {
		setObservations(prevObservations => {
			// Use a function to get the previous state and return the new state
			const newObservations = { ...prevObservations }; // Create a shallow copy of the previous state
			// Update the nested value
			if (!newObservations[section]) {
				newObservations[section] = {};
			}
			if (!newObservations[section][element]) {
				newObservations[section][element] = {};
			}

			newObservations[section][element].sentence = value;
			return newObservations; // Return the updated state
		});
	};
	
	if (isLoading || userLoading || measurementsLoading) {
		return <>Loading</>
	}

	return (
		<Box paddingLeft='20px' paddingBottom='100px'>
			<label style={{ display: 'block' }}>
				<Heading as='h6' size='md'>Clinical Details</Heading>
			</label>
			<AutoResizeTextarea
				isReadOnly={getReadOnly()}
				border="2px solid #5686ce"
				value={demographics.clinicalDetails}
				marginBottom="1rem"
				onChange={e => {
					setDemographics({ ...demographics, clinicalDetails: e.target.value })
					if (detectChanges) {
						detectChanges();
					}
				}}
			/>

			<Flex gap="1rem" marginBottom="1rem">
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Gender</Heading>
					</label>

					<Select
						variant="dark-mode-border"
						disabled={getReadOnly()}
						value={demographics.gender}
						onChange={e => {
							setDemographics({ ...demographics, gender: e.target.value as 'MALE' | 'FEMALE' | 'NA' })
							if(detectChanges) {detectChanges();}
						}}
					>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}} value="" disabled>Select Gender</option>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  value="MALE">Male</option>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  value="FEMALE">Female</option>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  value="NA">NA</option>
					</Select>
				</Box>

				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Sonographer</Heading>
					</label>

					<Select
						disabled={getReadOnly()}
						variant="dark-mode-border"
						value={demographics.sonographer}
						onChange={e => {
							setDemographics({ ...demographics, sonographer: e.target.value })
							if(detectChanges) {detectChanges();}
						}}
					>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  defaultValue="">N/A</option>
						{sonographers.map(item => (
							<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  key={item} value={item}>{item}</option>
						))}
					</Select>
				</Box>

				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Trainee</Heading>
					</label>

					<Select
						disabled={getReadOnly()}
						variant="dark-mode-border"
						value={demographics.trainee}
						onChange={e => {
							setDemographics({ ...demographics, trainee: e.target.value })
							if(detectChanges) {detectChanges();}
						}}
					>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  defaultValue="">N/A</option>
						{trainees.map(item => (
							<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  key={item} value={item}>{item}</option>
						))}
					</Select>
				</Box>
			</Flex>

			<Flex gap="1rem" marginBottom="1rem">
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Referring Doctor</Heading>
					</label>

					<Flex gap="0.5rem">
						<Input
							isReadOnly={getReadOnly()}
							variant="dark-mode-border"
							style={{ flex: '1' }}
							placeholder='Dr'
							value={demographics.referringDoctor.prefix}
							onChange={e =>
							{
								if(
									detectChanges
								) {
									detectChanges();
								}

								setDemographics(prevDemographics => ({
									...prevDemographics,
									referringDoctor: {
										...prevDemographics.referringDoctor,
										prefix: e.target.value,
									},
								}))
							}}
						/>
						<Input
							isReadOnly={getReadOnly()}
							variant="dark-mode-border"
							style={{ flex: '2' }}
							placeholder='First Name'
							value={demographics.referringDoctor.firstName}
							onChange={e => {
								if(detectChanges) {detectChanges();}
								setDemographics(prevDemographics => ({
									...prevDemographics,
									referringDoctor: {
										...prevDemographics.referringDoctor,
										firstName: e.target.value,
									},
								}))
							}}
						/>
						<Input
							isReadOnly={getReadOnly()}
							variant="dark-mode-border"
							style={{ flex: '2' }}
							placeholder='Last Name'
							value={demographics.referringDoctor.lastName}
							onChange={e => {
								if(detectChanges) {detectChanges();}
								setDemographics(prevDemographics => ({
									...prevDemographics,
									referringDoctor: {
										...prevDemographics.referringDoctor,
										lastName: e.target.value,
									},
								}))
							}}
						/>
					</Flex>
				</Box>

				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Assigned Doctor</Heading>
					</label>

					<Select
						disabled={getReadOnly()}
						variant="dark-mode-border"
						value={demographics.assignedDoctor}
						onChange={e => {
							setDemographics({ ...demographics, assignedDoctor: e.target.value })
							if(detectChanges) {detectChanges();}
						}}
					>
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  defaultValue="NA">N/A</option>
						{assignedDoctors.map(item => (
							<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  key={item} value={item}>{item}</option>
						))}
					</Select>
				</Box>
			</Flex>

			<Box as="span" flex='1' textAlign='left'>
				<Heading as='h5' size='md'>Templates</Heading>
			</Box>

			<Flex gap="1rem" marginBottom="2rem">
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Sentence Templates</Heading>
					</label>

					<Select
						value={sentenceTemplate}
						disabled={getReadOnly()}
						onChange={e => {
							if(detectChanges) {detectChanges();}
							setSentenceTemplate(e.target.value);
						}}
						variant="dark-mode-border" >
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  defaultValue="">N/A</option>
						{Object.keys(translations['sentenceTemplates']).map(item => (
							<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  key={item} value={item}>{item}</option>
						))}
					</Select>
				</Box>

				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Report Templates</Heading>
					</label>

					<Select
						disabled={getReadOnly()}
						variant="dark-mode-border"
						value={demographics.reportTemplate}
						onChange={e => {
							setDemographics({ ...demographics, reportTemplate: e.target.value })
							if(detectChanges) {detectChanges();}
						}}
					>
						{reportTemplates.map(item => (
							<option style={{'backgroundColor':'#373151'}}  key={item} value={item}>{item}</option>
						))}
					</Select>
				</Box>
			</Flex>

			{translations['advancedReportBuilder'] && Object.keys(translations['advancedReportBuilder']).map((section, sectionIndex) => {
				if (!translations['advancedReportBuilder'][section] || typeof translations['advancedReportBuilder'][section] !== 'object') {
					// If the section is not an object, don't attempt to map over its keys
					return null;
				}

				return (
					<div style={{ marginBottom: '1rem' }} className={`section ${section.replace(/\s/g, '').replace(/\//g, '')}`} key={`section-${section}-${sectionIndex}`}>
						<Accordion allowToggle defaultIndex={(store.userType === 'CARDIAC_SCIENTIST' || study.report?.reportStatus === 'CONFIRMED') ? 0 : undefined}>
							<AccordionItem border={0} sx={{ '.chakra-collapse': { overflow: 'visible !important' } }}>
								<AccordionButton paddingLeft='0'>
									<Heading as='h5' size='md' marginBottom={0} marginRight="1rem">{section}</Heading>
									<AccordionIcon />
								</AccordionButton>

								<AccordionPanel paddingLeft='0' paddingRight='0'>
									<div className="elements" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
										{translations['advancedReportBuilder'][section] && Object.keys(translations['advancedReportBuilder'][section]).map((element, elementIndex) => (
											<div className={`element ${element.replace(/\s/g, '').replace(/\//g, '')}`} key={`element-${element}-${elementIndex}`}>
												<label style={{ display: 'block' }}>
													<Heading as='h6' size='md'>{element}</Heading>
												</label>

												<div style={{ display: 'flex', alignItems: 'center' }}>
													<Checkbox
														className='newAdvancedReportBuilderCheckbox'
														isReadOnly={getReadOnly() || !observations[section]?.[element]?.value || observations[section]?.[element]?.value === 'N/A'}
														isChecked={observations && observations[section]?.[element]?.['conclusion']}
														borderColor="#5686ce"
														paddingRight='1rem'
														onChange={() => handleCheckboxChange(section, element)}
													/>
													<Select
														disabled={getReadOnly()}
														variant="dark-mode-border"
														style={{ width: '100%' }}
														value={(observations && observations[section]?.[element]?.value) ?? ''}
														onChange={e => handleOptionSelect(section, element, e.target.value || 'N/A')}
													>
														<option style={{'backgroundColor':'#373151','color':'#ffffff'}} defaultValue="" value=""></option>
														{Array.isArray(translations['advancedReportBuilder'][section][element]) && translations['advancedReportBuilder'][section][element].map((option: any) => (
															<option style={{'backgroundColor':'#373151','color':'#ffffff'}} key={option.display} value={option.display}>
																{option.display}
															</option>
														))}
													</Select>
												</div>
											</div>
										))}
									</div>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						{
							observations && observations[section] && Object.keys(observations[section]).map((element, index) => {
								const sentence = observations[section][element]?.sentence || '';

								if (sentence !== '') {
									return (
										<Flex alignItems="center" margin="0">
											<Box flex="1">
												<AutoResizeTextarea
													border="1px solid #919191"
													borderRadius="0"
													isReadOnly={getReadOnly()}
													_selection={{ 'color': '#ffffff' }}
													value={cleanSentence(sentence)}
													onChange={e => updateSentenceState(section, element, e.target.value)}
												/>
											</Box>
										</Flex>
									);
								}
								return null;
							})
						}
					</div>
				);
			})}

			<Heading as='h5' size='md'>Recommendations</Heading>

			<AutoResizeTextarea
				isReadOnly={getReadOnly()}
				border="2px solid #5686ce"
				_selection={{
					'color':'#ffffff'
				}}
				value={recommendations}
				onChange={e => {
					if(detectChanges) {detectChanges();}
					setRecommendations(e.target.value);
				}}
			/>

			<Heading as='h4' size='md'>Conclusions</Heading>

			<Button colorScheme="blue" isDisabled={getReadOnly()} onClick={() => {
				if(detectChanges) {detectChanges();}
				addEmptyConclusion();
			}}>Add</Button>

			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<Box ref={provided.innerRef} {...provided.droppableProps}>
							{conclusions.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={getReadOnly()}>
									{provided => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											p={2}
											mb={2}
											borderColor="transparent"
											borderRadius="md"
											backgroundColor="#373151"
											display="flex"
											alignItems="center"
										>
											<Box {...provided.dragHandleProps} mr={2}>
												<DragHandleIcon />
											</Box>
											<AutoResizeTextarea
												isReadOnly={getReadOnly()}
												border="2px solid #5686ce"
												_selection={{
													'color':'#ffffff'
												}}
												value={item.sentence}
												onChange={e => {
													if(detectChanges) {detectChanges();}
													const conclusionIndex = conclusions.findIndex(conclusion => conclusion.id === item.id);
													const updatedConclusions = [...conclusions];
													updatedConclusions[conclusionIndex].sentence = e.target.value;
													setConclusions(updatedConclusions);
												}}
											/>
											<IconButton
												isDisabled={getReadOnly()}
												icon={<DeleteIcon color="red.600" />}
												onClick={() => {
													if(detectChanges) {detectChanges();}
													handleDeleteItem(item.id);
												}}
												ml={2}
												size="sm"
												aria-label="Delete Item"
											/>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>

			<Heading as='h5' size='md'>CC Recipients</Heading>

			<AutoResizeTextarea
				isReadOnly={getReadOnly()}
				border="2px solid #5686ce"
				_selection={{
					'color':'#ffffff'
				}}
				value={ccRecipients}
				onChange={e => {
					setCcRecipients(e.target.value);
				}}
			/>

			{!getReadOnly() && (
				<SaveModal editable={checkEditable()} onSave={saveObservations} urgent={study.urgent} confirm={study.report?.reportStatus === 'CONFIRMED'} />
			)}

			<UrgencyConfirmationModal
				isOpen={isUrgencyModalOpen}
				onClose={() => setIsUrgencyModalOpen(false)}
				onConfirm={() => {
					setIsUrgencyModalOpen(false)
					saveUrgentStudy(urgent, exiting, confirming)
				}}
			/>

			<ConfirmedAlreadyModal
				isOpen={confirmedAlreadyModalOpen}
				onClose={() => setConfirmedAlreadyModalOpen(false)}
				onConfirm={() => {
					setConfirmedAlreadyModalOpen(false)
					saveStudy(urgent, exiting, confirming)
				}}
			/>
		</Box>
	);
}
export default forwardRef(NewAdvancedReportBuilder);