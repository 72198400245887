import * as React from 'react';
import { action } from 'mobx';
import {withRouter, WithRouterProps}  from '../Util/withRouter';
import * as AdminPages from './Pages/Admin/Entity';

import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from './Components/Topbar/Topbar';
import PageLinks from './Pages/Admin/PageLinks';
import Spinner from 'Views/Components/Spinner/Spinner';
import { Navigate, Route, Routes } from 'react-router';
import { store } from 'Models/Store';
import { ProtectedRoute } from './Components/Auth/ProtectedRoute';

const GraphiQlLazy = React.lazy(() => import('./Pages/Admin/Graphiql'));

class Admin extends React.Component<WithRouterProps> {
	private path = this.props.location.pathname === '' ? '' : this.props.location.pathname;
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}

	public componentDidMount() {
		this.setAppLocation();
	}

	public render() {
		return (
			<div className="body-container">
				<Topbar currentLocation="admin" />
				<div className="admin">
					<ProtectedRoute>
						<this.adminSwitch />
					</ProtectedRoute>
				</div>
			</div>
		);
	}

	private adminSwitch = () => {
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Navigate to="/404" />;
		}

		return (
			<>
				<PageLinks {...this.props} />

				<div className="body-content">
					<Routes>
						<Route path={''} element={<AdminPage />} />
						<Route path={'User'} element={<AllUsersPage />} />
						<Route path={'AhiDatastoreEntity/*'} element={<AdminPages.AhiDatastorePage />} />
						<Route path={'AssetEntity/*'} element={<AdminPages.AssetEntityPage />} />
						<Route path={'AuditEntity/*'} element={<AdminPages.AuditEntityPage />} />
						<Route path={'DeviceEntity/*'} element={<AdminPages.DeviceEntityPage />} />
						<Route path={'DoctorEntity/*'} element={<AdminPages.DoctorEntityPage />} />
						<Route path={'DicomMappingEntity/*'} element={<AdminPages.DicomMappingEntityPage />} />
						<Route path={'HolterReportEntity/*'} element={<AdminPages.HolterReportEntityPage />} />
						<Route path={'MeasurementEntity/*'} element={<AdminPages.MeasurementEntityPage />} />
						<Route path={'PatientEntity/*'} element={<AdminPages.PatientEntityPage />} />
						<Route path={'ReportEntity/*'} element={<AdminPages.ReportEntityPage />} />
						<Route path={'ReportTemplateEntity/*'} element={<AdminPages.ReportTemplateEntityPage />} />
						<Route path={'ServiceEntity/*'} element={<AdminPages.ServiceEntityPage />} />
						<Route path={'SessionEntity/*'} element={<AdminPages.SessionEntityPage />} />
						<Route path={'ShareLinkEntity/*'} element={<AdminPages.ShareLinkEntityPage />} />
						<Route path={'SiteEntity/*'} element={<AdminPages.SiteEntityPage />} />
						<Route path={'StructuredReportEntity/*'} element={<AdminPages.StructuredReportEntityPage />} />
						<Route path={'StudyEntity/*'} element={<AdminPages.StudyEntityPage />} />
						<Route path={'StudyFileEntity/*'} element={<AdminPages.StudyFileEntityPage />} />
						<Route path={'SuperAdminEntity/*'} element={<AdminPages.SuperAdminEntityPage />} />
						<Route path={'UserEntity/*'} element={<AdminPages.UserEntityPage />} />
						<Route path={'UserSiteAccessEntity/*'} element={<AdminPages.UserSiteAccessEntityPage />} />
						<Route path={'UserSiteRequestEntity/*'} element={<AdminPages.UserSiteRequestEntityPage />} />
						<Route path={'HL7ProviderEntity/*'} element={<AdminPages.HL7ProviderPage />} />
					</Routes>
				</div>
			</>
		);
	}
}

export default withRouter(Admin);